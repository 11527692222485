





















	import {
		Component,
		Vue,
		Prop,
		Watch,
		Emit,
	} from 'vue-property-decorator';
	import {
		Chart,
		ChartData,
		ChartEvent,
		InteractionItem,
		InteractionOptions,
		registerables,
	} from 'chart.js';
	import ChartDataLabels from 'chartjs-plugin-datalabels';
	import { getRelativePosition } from 'chart.js/helpers';
	import { LineChart as Text } from '@/config/Text.json';
	@Component({
		name: 'LineChart',
	})
	export default class LineChart extends Vue {
		@Prop({
			type: String,
			required: false,
		})
		readonly title!: string;
		@Prop({
			type: String,
			required: false,
		})
		readonly subtitle!: string;

		@Prop({
			type: Object as () => ChartData,
			required: true,
		})
		readonly data!: ChartData;

		@Prop({
			type: Array as () => Array<number>,
		})
		readonly extraData!: Array<number>;

		@Prop({
			type: String,
			required: false,
			default: '',
		})
		readonly titleText!: string;

		public chart!: Chart;
		mounted(): void {
			console.log(this.data);
			console.log("post perDay");
			console.log(this.perDay(this.data));
			const chart: HTMLCanvasElement = this.$refs[
				'chart'
			] as HTMLCanvasElement;

			Chart.register(...registerables);
			Chart.register(ChartDataLabels);

			const chartInstance: Chart = new Chart(chart, {
				plugins: [
					{
						id: 'options.plugins.legend',
					},
				],
				type: 'line',
				data: this.perDay(this.data),
				options: {
					events: ['click', 'mousemove', 'touchstart'],
					plugins: {
						legend: {
							display: false,
						},
						datalabels: {
							formatter: (value) =>
								Number(value).toFixed(1) +
								'%',
						},
						tooltip: {
							enabled: true,
							intersect: true,
							mode: 'point',
							callbacks: {
								title: (items) =>
									items[0].label,
								label: (items) => {
									let text = '';
									if (
										this.extraData ===
										undefined
									) {
										text = `${
											this.$t("LineChart.toolTips.label") as string
										}${Number(
											items.dataset
												.data[
												items
													.dataIndex
											]
										).toFixed(1)}%`;
									} else {
										text = `${
											this.$t("LineChart.toolTips.label") as string
										}	${this.extraData[
											items.dataIndex
										].toFixed(1)}%`;
									}
									return text;
								},
							},
						},
					},
					scales: {
						y: {
							display: true,
							title: {
								display: true,
								text: this.titleText,
							},
							max: 100,
						},
						x: {
							display: true,
						},
					},
					onClick: this.labelClick,
				},
			});

			this.chart = chartInstance;
		}

		@Watch('data')
		changedData(newVal: ChartData): void {
			console.log(newVal);

			this.chart.data = newVal;
			this.chart.update();
		}

		@Emit()
		public onClickBar(payload: string): string {
			return payload;
		}

		labelClick(event: ChartEvent | MouseEvent): void {
			const posClicked = getRelativePosition(
				event as MouseEvent,
				this.chart
			);
			console.log(event);
			console.log(posClicked);

			const activeClick = this.chart.getElementsAtEventForMode(
				event as Event,
				'point',
				this.chart.options as InteractionOptions,
				false
			);
			console.log(activeClick);
			if (activeClick.length > 0) {
				const elementClicked: InteractionItem =
					activeClick[0];
				const label: string = this.chart.data.labels![
					elementClicked.index
				] as string;
				const value = this.chart.data.datasets[
					elementClicked.index
				];
				console.log(label, value);
				this.onClickBar(label);
			}
		}
        public perDay(datachart: ChartData): ChartData {
            let labels = datachart.labels ? datachart.labels : [];
            if (labels.length === 0) {
                console.log("sin labels");
                return datachart;
            }
            let l_0 = labels[0];
            let arr = datachart.datasets[0].data;
            let new_data = [];
            let new_label = [];
            let index = 0;
            while (index < arr.length) {
                while (l_0 === labels[index]) {
                    index += 1;
                }
                new_label.push(l_0);
                new_data.push(arr[index-1]);
                l_0 = labels[index];
            }
            let new_datachart: ChartData = {
                labels: new_label,
                datasets: [
                    {
                        data: new_data,
						pointBorderColor: 'lime',
						borderColor: 'lime',
                    },
                ],
            };
            //console.log(new_datachart);
			return new_datachart;
        }
	}
