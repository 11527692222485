
































import {
	Component,
	Prop,
	Emit,
	Watch,
} from 'vue-property-decorator';
import LineChart from '@/components/main/LineChart.vue';
import AppDialog from '@/components/util/AppDialog.vue';
import { ChartData } from 'chart.js';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import MixinMain from '@/mixing/MixinMain.vue';
import { AxiosResponse } from 'axios';
import { IEvTipLlamCliente } from '@/model/main/IEvTipLlamCliente';
import { LineDialog as Text } from '@/config/Text.json';
import FilterRowChart from '@/components/main/FilterRowChart.vue';
import { IDateSelected } from '@/model/main/IDateSelected';
@Component({
	name: 'LineDialog',
	components: {
		LineChart,
		AppDialog,
		FilterRowChart,
	},
})
export default class LineDialog extends MixinMain {
	@Prop({
		type: String,
		required: true,
	})
	readonly title!: string;

	@Prop({
		type: String,
		required: false,
	})
	readonly subtitle!: string;

	@Prop({
		type: Boolean,
		required: true,
	})
	readonly isOpen!: boolean;

	@Prop({
		type: String,
		required: true,
	})
	readonly selectedLabel!: string;

	public dateEnd = '';
	public dateStart = '';

	public data: ChartData = {
		datasets: [],
	};
	public isLoading = false;
	public titleText = this.$t("LineDialog.titleText");
	public listenOpen(payload: boolean): void {
		this.onOpen();
	}

	@Emit()
	public onOpen(): boolean {
		return false;
	}

	@Watch('isOpen')
	public chagendIsOpen(newVal: boolean) {
		console.log(newVal);
		if (newVal) {
			this.getData();
		}
	}

	mounted(): void {
		if (this.isOpen) {
			this.getData();
			this.dateEnd = this.getFilter.dateEnd;
			this.dateStart = this.getFilter.dateTo;
		}
	}
	public getData(): void {
		this.isLoading = true;
		const request_evAudios = internet
			.newRequest()
			.get(
				`vue-xentric/evAudiosTipoLlamCliente?${this.getFilterUrl}`
			);
		Util.waitForPromises([request_evAudios])
			.then((response) => {
				const response_1 = response[0] as AxiosResponse;
				const convert = Util.reduceByProperty<
					IEvTipLlamCliente,
					'tipo_llamada'
				>(response_1.data, 'tipo_llamada');
				let data: Array<number> = [];
				let labels: Array<string> = [];
				console.log(convert);
				for (let item of convert[
					this.selectedLabel
				] as Array<IEvTipLlamCliente>) {
					data.push(item.Resultado);
					labels.push(item.fechaAnalisis!);
				}
				// this.data.datasets.push({
				// 	data,
				// });
				let newValues = [];
				let newLabels = [];
				let dic = Util.dateAndValueToDictionary(labels,data);
					
				for (let i of dic){
					newLabels.push(i.date)
					newValues.push(i.value)
				}
				data = newValues;
				labels = newLabels;
				this.data = JSON.parse(
					JSON.stringify({
						labels,
						datasets: [
							{
								data: Util.acumArray(
									data
								),
							},
						],
					})
				);
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}

	public listenDateSelected(payload: IDateSelected): void {
		if (
			payload.dateTo !== this.dateStart ||
			payload.dateEnd !== this.dateEnd
		) {
			this.filterByDate(payload);
		}
	}

	private filterByDate(dateFilter: IDateSelected) {
		this.isLoading = true;
		this.dateEnd = dateFilter.dateEnd;
		this.dateStart = dateFilter.dateTo;
		internet
			.newRequest()
			.get(
				`vue-xentric/evAudiosTipoLlamCliente/${dateFilter.dateTo}/${dateFilter.dateEnd}`
			)
			.then((response: AxiosResponse) => {
				const dataset: Array<IEvTipLlamCliente> =
					response.data;
				if (
					dataset !== undefined &&
					dataset.length > 0
				) {
					const convert = Util.reduceByProperty<
						IEvTipLlamCliente,
						'tipo_llamada'
					>(response.data, 'tipo_llamada');
					let data: Array<number> = [];
					let labels: Array<string> = [];
					console.log(convert);
					for (let item of convert[
						this.selectedLabel
					] as Array<IEvTipLlamCliente>) {
						data.push(item.Resultado);
						labels.push(item.fechaAnalisis!);
					}
					// this.data.datasets = [
					// 	{
					// 		data,
					// 	},
					// ];
					let newValues = [];
					let newLabels = [];
					let dic = Util.dateAndValueToDictionary(labels,data);
					
					for (let i of dic){
						newLabels.push(i.date)
						newValues.push(i.value)
					}
					data = newValues;
					labels = newLabels;
					this.data = JSON.parse(
						JSON.stringify({
							labels,
							datasets: [
								{
									data: Util.acumArray(
										data
									),
								},
							],
						})
					);
				} else {
					this.data = JSON.parse(
						JSON.stringify({
							labels: [],
							dataset: [
								{
									data: [],
								},
							],
						})
					);
				}
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}
}
