






























































































































	import { Component, Prop, Watch } from 'vue-property-decorator';
	import MainMixin from '@/mixing/MixinMain.vue';
	import { IFilterData } from '@/model/main/IFilterData';
	import { IEvCat } from '@/model/main/IEvCat';
	import { IItemSelect } from '@/model/util/IItemSelect';
	import domtoimage from "dom-to-image";
	import jsPDF from "jspdf"

	@Component({
		name: 'FilterBar',
	})
	export default class FilterBar extends MainMixin {
		@Prop({
			type: Boolean,
			required: true,
		})
		readonly isDisabled!: boolean;
		public maxDate: string = new Date()
			.toISOString()
			.slice(0, 10);
		public isDateEnd = false;
		public isDateTo = false;
		public filterNow: IFilterData = {
			dateTo: '',
			dateEnd: '',
			categories: [],
			camping: [],
			customer: '',
		};
		public selectedElement: Array<string> = [];
		public dateToText = this.$t("FilterBar.inputDateTo");
		public dateEndText = this.$t("FilterBar.inputDateEnd");
		public categoriesText = this.$t("FilterBar.selectEvtCat");
		public filterText = this.$t("FilterBar.filterButtonText");
		public downloadText = this.$t("FilterBar.downloadButtonText");
		public TextDownloadPDF = this.$t("FilterBar.textDownload");
		public textOther = this.$t("FilterBar.otros");
		mounted(): void {
			if (this.getFilter !== undefined) {
				this.filterNow = this.getFilter;
				this.fillSelectedElement();
			}
		}
		public get getEvCatSelect(): Array<IItemSelect> {
			return this.getEvCat.map(
				(cat: IEvCat): IItemSelect => {
					return {
						text: cat.categoria,
						value: cat.categoria,
					};
				}
			);
		}
		private fillSelectedElement() {
			this.selectedElement = [];
			for (let item of this.getFilter.categories as Array<
				IEvCat
			>) {
				if (!this.selectedElement.includes(item.categoria)) {
					this.selectedElement.push(item.categoria);
				}
			}
		}

		// public get getCampingSelect(): IItemSelect {
		// 	return {
		// 		text: this.getCamping.campania,
		// 		value: this.getCamping.campania,
		// 	};
		// }

		@Watch('getFilter')
		public changedFilter(newVal: IFilterData) {
			this.filterNow = newVal;
			this.fillSelectedElement();
		}

		public newFilter(): void {
			const evCat: Array<IEvCat> = [];
			for (let selected of this.selectedElement) {
				const finded: IEvCat = this.getEvCat.find((e) => {
					return e.categoria == selected;
				}) as IEvCat;
				evCat.push(finded);
			}
			const newFilter: IFilterData = {
				dateTo: this.filterNow.dateTo,
				dateEnd: this.filterNow.dateEnd,
				categories: evCat,
				camping: this.filterNow.camping,
				customer: this.filterNow.customer,
			};

			this.updateFilter(newFilter);
		}
		public get downloadFile(): string {
			const now = new Date();
			let nameLogFile = `log_${localStorage.getItem(
				'client'
			)}_${
				this.getFilter.camping
			}_${now.getFullYear()}${now.getMonth() + 1}.txt`;
			return `${process.env.VUE_APP_API}/rutaLogSftp/?token=${localStorage.getItem('TOKEN')}&&ruta=/home/Resemblyzer/dataset/log/${nameLogFile}`;
		}
		public downloadWithCSS() {
            domtoimage
            .toPng(document.querySelector('#dashboard_main')!)
            .then(function(dataUrl: any) {
                var img = new Image();
                img.src = dataUrl;
                img.onload = function () {
                    var doc :any =''
					//console.log("img:", img.width,  img.height)
                    if (img.width > img.height) {
                        doc = new jsPDF('l', 'mm', [img.width, img.height])
                    } else {
                        doc = new jsPDF('p', 'mm', [img.width, img.height])
                    }          
                    doc.addImage (img, 'jpeg', 10, 10, img.width, img.height) // La relación se puede ajustar según sea necesario
                    const date = new Date();
                    const filename =
                        "dashboard - " +
                        date.getFullYear() +
                        ("0" + (date.getMonth() + 1)).slice(-2) +
                        ("0" + date.getDate()).slice(-2) +
                        ("0" + date.getHours()).slice(-2) +
                        ("0" + date.getMinutes()).slice(-2) +
                        ("0" + date.getSeconds()).slice(-2) +
                        ".pdf";
                    doc.save(filename);
                };
            })
            .catch(function(error: any) {
                console.error("oops, something went wrong!", error);
            });
        }
		public cutString(text:string, number:number): string {
			if (text.length >= number) {
				return text.substring(0, number - 3) + '...';
			}
			else {
				return text;
			}
		}
	}
