
























































import {
	Component,
	Mixins,
	Watch,
} from 'vue-property-decorator';
import Rating from '@/components/main/Rating.vue';
import Chart from '@/components/util/BarChart.vue';
import { IRating } from '@/model/util/IRating';
import AppContainerBase from '@/components/util/AppContainerBase.vue';
import { MainMenu as MainMenuText } from '@/config/Text.json';
import RowRating from '@/components/main/RowRating.vue';
import AppDialog from '@/components/util/AppDialog.vue';
import FormDialog from '@/components/main/FormDialog.vue';
import LineDialog from '@/components/main/LineDialog.vue';
import MixinMain from '@/mixing/MixinMain.vue';
import { IFilterData } from '@/model/main/IFilterData';
import { IEvTipLlamCliente } from '@/model/main/IEvTipLlamCliente';
import Util from '@/utils/Util';
import { internet } from '@/utils/Internet';
import { AxiosResponse } from 'axios';
import { IEvCat } from '@/model/main/IEvCat';
import { ChartData } from 'chart.js';
import TableClassificactionCategory from '@/components/main/TableClassificationCategory.vue';
import TableProductCategory from '@/components/main/TableProductCategory.vue';
import FilterBar from '@/components/main/FilterBar.vue';
import BottonDownload from '@/components/main/BottonDownload.vue';
import WordCloud from '@/components/main/WordCloud.vue';
import ProgressChart from '@/components/main/ProgressChart.vue';

@Component({
	name: 'MainMenu',
	components: {
		Rating,
		Chart,
		AppContainerBase,
		RowRating,
		AppDialog,
		FormDialog,
		LineDialog,
		TableClassificactionCategory,
		TableProductCategory,
		FilterBar,
		WordCloud,
		BottonDownload,
		ProgressChart,
	},
})
export default class MainMenu extends Mixins(MixinMain) {
	// MainComponent
	public title = this.$t("MainMenu.title");
	public isLoading = true;
	// Dialog Filter
	public dialogTitle = this.$t("MainMenu.dialogTitle");
	public isOpen = false;
	// Ratings
	public ratings: Array<IRating> = [
		{
			name: '',
			percentage: 0,
			record: 0,
		},
		{
			name: '',
			percentage: 0,
			record: 0,
		},
		{
			name: '',
			percentage: 0,
			record: 0,
		},
		{
			name: '',
			percentage: 0,
			record: 0,
		},
		{
			name: '',
			percentage: 0,
			record: 0,
		},
		{
			name: '',
			percentage: 0,
			record: 0,
		},
	];
	// Bar Chart
	public chartEvTipLlam: ChartData = {
		datasets: [],
	};
	public titleChart = this.$t("MainMenu.titleBar");
	public subTitleChart = this.$t("MainMenu.titleBarChar");
	public extraData: Array<number> = [];
	// LineChart Dialog
	public dataFormated: Record<
		string,
		Array<IEvTipLlamCliente>
	> = {};
	public selectedLabel = '';
	public chartLineTitle = '';
	public chartLineOpen = false;
	public reRenderVar = 0;
	mounted(): void {
		if (this.getFilter === undefined) {
			this.isOpen = true;
		}else if (this.getFilter.customer === "") {
			this.isOpen = true;
		} else {
			this.getAllData();
		}
	}

	@Watch('getFilter')
	filterChanged(newVal: IFilterData): void {
		this.isOpen = false;

		this.getAllData();
	}

	public getAllData(): void {
		this.isLoading = true;
		const request_evClienteCateg = internet
			.newRequest()
			.get(
				`vue-xentric/evClienteCateg?${this.getFilterUrl}`
			);
		const request_evTip = internet
			.newRequest()
			.get(
				`vue-xentric/evTipLlamCliente?${this.getFilterUrl}`
			);
		const request_evAudios = internet
			.newRequest()
			.get(
				`vue-xentric/evAudiosTipoLlamCliente?${this.getFilterUrl}`
			);
		Util.waitForPromises([
			request_evClienteCateg,
			request_evTip,
			request_evAudios,
		])
			.then((result) => {
				// Ratings
				const result_1 = result[0] as AxiosResponse;
				this.evaluateEvClienteCate(result_1);
				// Chart EvTipLlamCliente
				const result_2 = result[1] as AxiosResponse;
				this.evaluateEvTipLlamCliente(result_2);
				// Chart EvAudiosTipoLlamCliente
				const result_3 = result[2] as AxiosResponse;
				console.log(result_3);

				const reduce = Util.reduceByProperty<
					IEvTipLlamCliente,
					'tipo_llamada'
				>(result_3.data, 'tipo_llamada');
				this.dataFormated = reduce;
				const arrayOfIEvTip: Array<IEvTipLlamCliente> =
					result_2.data;
				this.extraData = [];
				for (let evTipLlam of arrayOfIEvTip) {
					this.extraData.push(
						reduce[evTipLlam.tipo_llamada]
							.length
					);
				}
				this.subTitleChart = Util.replaceTextWith(
					this.$t("MainMenu.titleBarChar") as string,
					'%s',
					result_3.data.length
				);
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}
	private evaluateEvClienteCate(
		response: AxiosResponse
	): void {
		const ratings: Array<IRating> = (response.data as Array<
			IEvCat
		>).map(
			(e): IRating => {
				return {
					name: e.categoria,
					percentage: Number.parseInt(
						e.Resultado.toFixed(0)
					),
					record: e.PuntajeObtenido,
				};
			}
		);
		this.ratings = ratings;
	}
	private evaluateEvTipLlamCliente(response: AxiosResponse) {
		const dataEvTipLlam: Array<IEvTipLlamCliente> =
			response.data;
		this.$set(
			this.chartEvTipLlam,
			'labels',
			dataEvTipLlam.map(
				(ele: IEvTipLlamCliente): string => {
					return Util.replaceTextWith(
						ele.tipo_llamada,
						'_',
						' '
					);
				}
			)
		);
		this.chartEvTipLlam.datasets = [
			{
				data: dataEvTipLlam.map(
					(ele: IEvTipLlamCliente): number => {
						return ele.Resultado;
					}
				),
			},
		];
	}

	public listenBarChartClicked(payload: string) {
		this.chartLineOpen = true;
		this.chartLineTitle = Util.replaceTextWith(
			this.$t("MainMenu.lineChartTitle") as string,
			'%s',
			payload
		);
		const labelOrigen = Util.replaceTextWith(
			payload,
			' ',
			'_'
		);
		this.selectedLabel = labelOrigen;
	}

	public listenOpen(payload: boolean): void {
		console.log('Escuchado');
		this.chartLineOpen = false;
	}

	public openFormDialog() {
		this.isOpen = true;
	}

	public reRender(lang:string) {
		console.log("cambiando a ", lang);
		this.titleChart = this.$t("MainMenu.titleBar") as string;
		this.subTitleChart = this.$t("MainMenu.titleBarChar") as string;
		this.reRenderVar += 1;
		this.$emit('changeEvent');
	}
}
