


















































import { Component, Watch } from 'vue-property-decorator';
import Rating from '@/components/main/Rating.vue';
import { IRating } from '@/model/util/IRating';
import MixinMain from '@/mixing/MixinMain.vue';
import { MainMenu } from '@/config/Text.json';
import { RowRating as Text } from '@/config/Text.json';
import { IFilterData } from '@/model/main/IFilterData';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { AxiosResponse } from 'axios';
import { IEvCat } from '@/model/main/IEvCat';
import LineDialogCat from '@/components/portfolio/LineDialogCat.vue';
@Component({
	name: 'RowRating',
	components: {
		Rating,
		LineDialogCat,
	},
})
export default class RowRating extends MixinMain {
	public ratings: Array<IRating> = [];
	public isLoading = true;
	public isOpen = false;
	public labelSelected = '';
	public titleDialog = this.$t("RowRating.titleDialog");
	public sumAllrecord = 0;
	@Watch('getFilter')
	public changedGetFilter(newVal: IFilterData) {
		this.ratings = [];
		this.getData();
	}
	mounted(): void {
		if (this.getFilter !== undefined) {
			this.setCampings();
			this.getData();
		}
	}
	getIconForPosition(index: number): string {
		return MainMenu.icons[index];
	}
	public openModal(name: string, index: number): void {
		if (index < this.ratings.length - 1) {
			this.titleDialog = Util.replaceTextWith(
				this.$t("RowRating.titleDialog") as string,
				'%s',
				name
			);
			this.labelSelected = name;
			this.isOpen = true;
		}
	}
	public listenDialogClose(payload: boolean) {
		this.isOpen = false;
	}
	public getData(): void {
		this.isLoading = true;
		const request_evClienteCateg = internet
			.newRequest()
			.get(
				`vue-xentric/evClienteCateg?${this.getFilterUrl}`
			);
		const request_evCLientTotal = internet
			.newRequest()
			.get(
				`vue-xentric/evCarteraTotalCliente?${this.getFilterUrl}`
			);
		const request_evAudiosTipo = internet
			.newRequest()
			.get(
				`vue-xentric/evAudiosTipoLlamCamp?${this.getFilterUrl}`
			);
		Util.waitForPromises([
			request_evClienteCateg,
			request_evCLientTotal,
			request_evAudiosTipo,
		])
			.then((response) => {
				// Ratings
				const result_1 = response[0] as AxiosResponse;
				this.evaluateEvClienteCate(result_1);
				const result_2 = response[1] as AxiosResponse;
				const percentage: number = parseFloat(
					result_2.data[0].Resultado
				);
				this.ratings.push({
					name: this.$t("RowRating.portafolioEvaluation") as string,
					percentage: Number.parseFloat(
						percentage.toFixed(1)
					),
					record: 0,
				});
				this.sumAllrecord = response[2].data.length;
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}

	private evaluateEvClienteCate(
		response: AxiosResponse
	): void {
		const ratings: Array<IRating> = (response.data as Array<
			IEvCat
		>).map(
			(e): IRating => {
				return {
					name: e.categoria,
					percentage: Number.parseFloat(
						e.Resultado.toFixed(1)
					),
					record: e.PuntajeObtenido,
				};
			}
		);
		this.ratings = ratings;
	}
	private setCampings() : void {
		const campsSelected : Array<string> = [];
		for (let camp of this.getCamping) {
			campsSelected.push(camp.campania as string)
		}
		let new_filter = this.getFilter;
		new_filter.camping = campsSelected;
		this.updateFilter(new_filter);
	}
}
