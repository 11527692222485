

































































import {
	Component,
	Vue,
	Prop,
	Watch,
	Emit,
} from 'vue-property-decorator';
import ChartJS, {
	ActiveElement,
	Chart,
	ChartData,
	ChartEvent,
	ChartType,
	InteractionItem,
	InteractionOptions,
	registerables,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getRelativePosition } from 'chart.js/helpers';
import { BarChart as Text } from '@/config/Text.json';
@Component({
	name: 'ProgressChart',
})
export default class ProgressChart extends Vue {
	@Prop({
		type: Boolean,
		default: true,
	})
	readonly isLoading!: boolean;
	@Prop({
		type: String,
		required: true,
	})
	readonly title!: string;
	@Prop({
		type: String,
		required: false,
	})
	readonly subtitle!: string;
	@Prop({
		type: String,
		required: true,
	})
	readonly chartType!: string;
	@Prop({
		type: String,
		required: false,
		default: 'x',
	})
	readonly axis!: string;
	@Prop({
		type: Object as () => ChartData,
		required: true,
	})
	readonly data!: ChartData;

	@Prop({
		type: Array as () => Array<number>,
		default: () => Array<number>(),
	})
	readonly extraData!: Array<number>;
	public chart!: Chart;

	private generateChart() {
		const chart: HTMLCanvasElement = this.$refs[
			'chart'
		] as HTMLCanvasElement;

		Chart.register(...registerables);
		Chart.register(ChartDataLabels);

		const chartInstance: Chart = new Chart(chart, {
			plugins: [
				{
					id: 'options.plugins.legend',
				},
			],
			type: this.chartType as ChartType,
			data: {
				datasets: [],
				labels: [],
			},
			options: {
				events: ['click', 'mousemove', 'touchstart'],
				indexAxis:
					this.axis === 'x' || this.axis === 'y'
						? this.axis
						: 'x',
				plugins: {
					legend: {
						display: false,
					},
					datalabels: {
						formatter: (value) =>
							Number(value).toFixed(1) +
							'%',
					},
					tooltip: {
						enabled: true,
						intersect: true,
						mode: 'point',
						callbacks: {
							title: (items) =>
								items[0].label,
							label: (items) =>
								`${this.$t("BarChart.toolTips.label") as string}${
									this.extraData[
										items.dataIndex
									]
								}`,
						},
					},
				},
				scales: {
					y: {
						display: true,
					},
					x: {
						display: true,
					},
				},
				onClick: this.labelClick,
			},
		});

		this.chart = chartInstance;
	}
	public getPercentage(value: number): string {
		return value.toFixed(1);
	}
	@Watch('data')
	changedData(newVal: ChartData) {
		console.log(newVal);
		this.chart.data = newVal;
		this.chart.update();
	}

	@Emit()
	public onClickBar(payload: string) {
		return payload;
	}

	labelClick(
		event: ChartEvent | MouseEvent,
		activeElement: ActiveElement[],
		chart: Chart
	) {
		const posClicked = getRelativePosition(
			event as MouseEvent,
			this.chart
		);
		console.log(event);
		console.log(posClicked);

		const activeClick = this.chart.getElementsAtEventForMode(
			event as Event,
			'point',
			this.chart.options as InteractionOptions,
			false
		);
		console.log(activeClick);
		if (activeClick.length > 0) {
			const elementClicked: InteractionItem =
				activeClick[0];
			const label: string = this.chart.data.labels![
				elementClicked.index
			] as string;
			const value = this.chart.data.datasets[
				elementClicked.index
			];
			console.log(label, value);
			this.onClickBar(label);
		}
	}
}
