import { EValidate, IValidate } from '@/model/util/ValidationType';
class Validation implements IValidate {
	getRules(rules: Array<EValidate>): Array<(...args: any) => void> {
		const output: any[] = [];
		for (let i = 0; i <= rules.length - 1; i++) {
			switch (rules[i]) {
				case EValidate.REQUIRED: {
					output.push(this.required);
				}
			}
		}
		return output;
	}
	required(value: any): boolean | string {
		return !!value || 'Campo requerido';
	}
}

export const validator = new Validation();
