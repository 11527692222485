





































































































import {
	Component,
	Emit,
	Prop,
	Vue,
} from 'vue-property-decorator';
import Util from '@/utils/Util';
import { Icon } from '@/model/util/Icon';
import { IDateSelected } from '@/model/main/IDateSelected';
@Component({
	name: 'FilterRowChart',
})
export default class FilterRowChart extends Vue {
	@Prop({
		type: Boolean,
		default: false,
	})
	readonly isLoading!: boolean;
	@Prop({
		type: String,
		required: false,
	})
	readonly propDateEnd!: string;

	@Prop({
		type: String,
		required: false,
	})
	readonly propDateStart!: string;

	readonly maxDate: string = new Date()
		.toISOString()
		.slice(0, 10);
	public dateEnd = '';
	public isDateEnd = false;
	public dateStart = '';
	public isDateStart = false;
	public labelEnd = this.$t("FilterRowChart.labelTextEnd");
	public labelStart = this.$t("FilterRowChart.labelTextStart");
	public btnsFilter : Array<string> = [
		this.$t("FilterRowChart.buttonsFilterTexts.0") as string,
		this.$t("FilterRowChart.buttonsFilterTexts.1") as string,
		this.$t("FilterRowChart.buttonsFilterTexts.2") as string,
		this.$t("FilterRowChart.buttonsFilterTexts.3") as string,
		this.$t("FilterRowChart.buttonsFilterTexts.4") as string,
		];
	mounted(): void {
		this.dateEnd = String(this.propDateEnd);
		this.dateStart = String(this.propDateStart);
	}

	public filterDate(btnText: string): void {
		const numberPart: number = parseInt(
			btnText.match(/(\d+)/)![0]
		);
		let textPart: string = btnText.match(/[a-zA-Z]+/)![0];

		textPart = textPart.toUpperCase();
		const now: Date = new Date();
		let end: Date = new Date();
		switch (textPart) {
			case 'D': {
				end.setDate(end.getDate() - numberPart);
				break;
			}
			case 'M': {
				end.setMonth(end.getMonth() - numberPart);
				break;
			}
			case 'A': {
				end.setFullYear(
					end.getFullYear() - numberPart
				);
			}
		}
		const dateStart: string = end
			.toISOString()
			.slice(0, 10);
		const dateEnd: string = now.toISOString().slice(0, 10);

		this.dateEnd = dateEnd;
		this.dateStart = dateStart;
		this.onDateSelected(this.dateStart, this.dateEnd);
	}

	public filterData(): void {
		if (this.dateStart === '' || this.dateEnd === '') {
			Util.showMessage(
				this.$t("FilterRowChart.infoDateRequired")  as string,
				Icon.INFO
			);
		} else {
			this.onDateSelected(this.dateStart, this.dateEnd);
		}
	}

	@Emit()
	public onDateSelected(
		dateStart: string,
		dateEnd: string
	): IDateSelected {
		//console.log('emitido');

		return {
			dateTo: dateStart,
			dateEnd: dateEnd,
		};
	}
}
