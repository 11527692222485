











































import MixinMain from '@/mixing/MixinMain.vue';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { Component, Watch } from 'vue-property-decorator';
import { WordCloudText as Text } from '@/config/Text.json';
import { IWord } from '@/model/main/IWord';
// @ts-ignore
import WordCloud from 'vue-d3-cloud';

@Component({
	name: 'AppWordCloud',
	components: {
		WordCloud,
	},
})

export default class AppWordCloud extends MixinMain {
	public isLoading = false;
	public title = this.$t("WordCloudText.title");
	public btnWordCloud = this.$t("WordCloudText.btnWordCloud");
	public words: Array<IWord> = [];
	private length = 0;
	public width = 0;
	mounted(): void {
		if (this.getFilter !== undefined) {
			this.getData();
			this.getWith();
			window.addEventListener('resize', this.getWith);
		}
	}
	destroyed(): void {
		window.removeEventListener('resize', this.getWith);
	}
	@Watch('getFilter')
	public changedGetFilter(): void {
		this.getData();
		this.getWith();
	}
	public fonSize = (word: IWord): number => {
		return Math.sqrt(word.value) * 10;
	};
	public getWith(): void {
		const ele = document.getElementById('refs');
		this.width = ele?.clientWidth as number;
		//this.width nos da el número de palabras que se estan mostrando en la nube de palabras
		//console.log( this.width);
	}

	private getData(): void {
		const reques_1 = internet
			.newRequest()
			.get(
				`vue-xentric/cloud-word/cliente?${this.getFilterUrl}&talker=all`
			);
		this.isLoading = true;
		Util.waitForPromises([reques_1])
			.then((responses) => {
				const data:any = responses[0].data
					// const groupAndCount: Record<
				// 	string,
				// 	number
				// > = {};
				this.words = [];
				for (const property in data) {
					//if (
					//	this.ignoreWord.indexOf(property) ===
					//	-1 &&
				//		property.match(/([a-zA-Z]{3,})/gi) !==
				//		null
				//		)
					this.words.push({
						text: property,
						value: data[property],
						
					});
					//console.log("estoy aca", data[property])
				}

				//console.log("words", this.words);
					// const data: Array<string> =
				// 	responses[0].data[0].TranscritoCliente;
				// const groupAndCount: Record<
				// 	string,
				// 	number
				// > = {};
				// for (let word of data) {
				// 	if (
				// 		this.ignoreWord.indexOf(word) ===
				// 			-1 &&
				// 		word.match(/([a-zA-Z]{3,})/gi) !==
				// 			null
				// 	) {
				// 		if (word in groupAndCount) {
				// 			groupAndCount[word]++;
				// 		} else {
				// 			groupAndCount[word] = 1;
				// 		}
				// 	}
				// }

				// for (let key of Object.keys(groupAndCount)) {
				// 	this.words.push({
				// 		text: key,
				// 		value: groupAndCount[key],
				// 	});
				// }
			
			
				this.words = (this.words.sort(
					this.compare
					) as Array<IWord>)//.slice(
						// con this.words.length - 1000 podemos configurar cuantas palabras queremos que se muestren en nuestra nube de palabras
						//this.words.length - 500,
						//this.words.length
						//);
						//this.length = this.words.length;
						//console.log(this.words);
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}
	public get isVisible(): boolean {
		return this.length === this.words.length;
	}
	private compare(a: IWord, b: IWord) {
		return a.value - b.value;
	}
}
